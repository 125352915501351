export const initializeGoogleSignIn = (clientId, callback) => {
    if (window.google && window.google.accounts) {
        // Initialize the Google Sign-In client
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: callback,
            ux_mode: 'popup',
        });

        // Render the Google Sign-In button
        window.google.accounts.id.renderButton(
            document.getElementById("googleSignInButton"),
            { theme: "outline", size: "large" , text: "continue_with" }
        );

        // Render the Google Sign-In button2
        window.google.accounts.id.renderButton(
            document.getElementById("googleSignInButton2"),
            { theme: "outline", size: "large" , text: "continue_with" }
        );

        // Optionally trigger the prompt for Google Sign-In
        window.google.accounts.id.prompt();
    } else {
        console.error("Google Identity Services library not loaded");
    }
};

export const handleCredentialResponse = async (token, apiUrl) => {
    try {
        const response = await fetch(`${apiUrl}/auth/google/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token }),
        });
        const data = await response.json();
        if (data.status === 'ok') {
            if (data.created){
                console.log('User created successfully');
            } else{
                console.log('User logged in successfully');
            }
            return { success: true, key: data.key , username: data.username, created: data.created };
        } else {
            console.error('Login failed:', data.message);
            return { success: false, message: data.message };
        }
    } catch (error) {
        console.error('Error:', error);
        return { success: false, message: 'An error occurred' };
    }
};
