import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import WorkflowTasks from '../../components/WorkflowDetails/WorkflowTasks'; 
import parse from 'html-react-parser'; 
import config from '../../config';
import styles from '../../styles/pages/UserDashboard/WorkflowDetail.module.css';

function WorkflowDetail() {
    const [workflow, setWorkflow] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Fetching workflow details for workflow ID:', id);

        const fetchWorkflow = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                setWorkflow(response.data);
            } catch (error) {
                console.error('Failed to fetch workflow details:', error);
            }
        };

        fetchWorkflow();
    }, [id]);

    if (!workflow || !workflow.workflow.tasks) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            {workflow.workflow ? (
                <Container className={`mt-5 ${styles.workflowDetailContainer}`}>
                    <Row className="m-5">
                        <Col>
                            <h2 className={styles.title}>{workflow.workflow.name}</h2>
                            <div className={styles.description}>{parse(workflow.workflow.description)}</div>
                        </Col>
                        <Card>
                            <Card.Body className={styles.cardBody}>
                                {/* Assuming WorkflowTasks accepts a prop for click handling */}
                                <WorkflowTasks tasks={workflow.workflow.tasks} workflowId={id} />
                            </Card.Body>
                        </Card>
                    </Row>
                </Container>
            ) : (
                <h2>Loading...</h2>
            )}
        </div>
    );
}

export default WorkflowDetail;
