import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/WorkflowBuilder/FieldConfigWindow.module.css';

function FieldConfigWindow({ field, onClose, onChange, showOriginalNavbar }) {
  const [activeTab, setActiveTab] = useState('general');
  const [fieldConfig, setFieldConfig] = useState(field.config || {});
  const [label, setLabel] = useState(field.label);
  const [required, setRequired] = useState(field.config?.required || false);
  const [helpText, setHelpText] = useState(field.config?.helpText || '');
  const [hoverText, setHoverText] = useState(field.config?.hoverText || '');
  const [defaultValue, setDefaultValue] = useState(field.config?.defaultValue || '');

  // Update the window when the field changes
  useEffect(() => {
    setFieldConfig(field.config || {});
    setLabel(field.label);
    setRequired(field.config?.required || false);
    setHelpText(field.config?.helpText || '');
    setHoverText(field.config?.hoverText || '');
    setDefaultValue(field.config?.defaultValue || '');
    console.log('Field updated:', field);
  }, [field]);

  useEffect(() => {
    // Set CSS variable dynamically based on the navbar's visibility
    const configWindowTop = showOriginalNavbar ? '102px' : '42px';
    document.documentElement.style.setProperty('--config-window-top', configWindowTop);
  }, [showOriginalNavbar]);

  // Update fieldConfig and propagate changes
  const handleFieldConfigChange = (key, value) => {
    setFieldConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };

  // Propagate changes to parent component whenever any relevant field changes
  useEffect(() => {
    onChange({
      ...field,
      label,
      config: { ...fieldConfig, required, helpText, hoverText, defaultValue },
    });
  }, [label, required, helpText, hoverText, defaultValue]);

  return (
    <div className={`${styles.configWindow} ${styles.slideInRight}`}>
      <div className={styles.header}>
        <h3>{field.type} Properties</h3>
        <button className={styles.closeButton} onClick={onClose}>
            <i className="fa fa-times"></i>
        </button>
      </div>
      <div className={styles.tabContainer}>
        <button
          className={activeTab === 'general' ? styles.activeTab : ''}
          onClick={() => setActiveTab('general')}
        >
          General
        </button>
        <button
          className={activeTab === 'advanced' ? styles.activeTab : ''}
          onClick={() => setActiveTab('advanced')}
        >
          Advanced
        </button>
      </div>
      
      {/* General Tab */}
      {activeTab === 'general' && (
        <div className={styles.tabContent}>
          <div className={styles.formGroup}>
            <label>Field Label</label>
            <input
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <p className={styles.description}>Enter the label for this field</p>
          </div>

          <div className={styles.formGroup}>
            <label>Required</label>
            <div className={styles.switchContainer}>
              <input
                type="checkbox"
                checked={required}
                onChange={(e) => {
                  setRequired(e.target.checked);
                  handleFieldConfigChange('required', e.target.checked);
                }}
              />
            </div>
            <p className={styles.description}>
              Prevent submission if this field is empty
            </p>
          </div>
        </div>
      )}

      {/* Advanced Tab */}
      {activeTab === 'advanced' && (
        <div className={styles.tabContent}>
          <div className={styles.formGroup}>
            <label>Help Text</label>
            <input
              type="text"
              value={helpText}
              onChange={(e) => {
                setHelpText(e.target.value);
                handleFieldConfigChange('helpText', e.target.value);
              }}
            />
            <p className={styles.description}>Add a short description below the field</p>
          </div>

          <div className={styles.formGroup}>
            <label>Hover Text</label>
            <input
              type="text"
              value={hoverText}
              onChange={(e) => {
                setHoverText(e.target.value);
                handleFieldConfigChange('hoverText', e.target.value);
              }}
            />
            <p className={styles.description}>
              Show a description when a user hovers over this field
            </p>
          </div>

          <div className={styles.formGroup}>
            <label>Default Value</label>
            <input
              type="text"
              value={defaultValue}
              onChange={(e) => {
                setDefaultValue(e.target.value);
                handleFieldConfigChange('defaultValue', e.target.value);
              }}
            />
            <p className={styles.description}>
              Set the default value for this field
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default FieldConfigWindow;
