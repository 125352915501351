import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../config';

function PasswordResetConfirm() {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validatePassword = (password) => {
        const validationErrors = [];
        if (password.length < 9) validationErrors.push('*Your password must contain at least 9 characters.');
        if (!/[A-Z]/.test(password) || !/[a-z]/.test(password))
            validationErrors.push('*Your password must contain both upper-case and lower-case letters.');
        if (!/\d/.test(password)) validationErrors.push('*Your password must contain at least one numeric digit.');
        if (!/[!@#$%^&*]/.test(password))
            validationErrors.push('*Your password must contain at least one special character.');
        return validationErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');

        // Validate passwords
        const errors = validatePassword(password);
        setPasswordErrors(errors);

        if (errors.length > 0) {
            setError('Please fix the password errors before submitting.');
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return;
        }

        setIsLoading(true); // Start loading

        try {
            const response = await fetch(`${config.apiUrl}/reset/${uidb64}/${token}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ new_password1: password, new_password2: confirmPassword }),
            });

            if (response.ok) {
                setMessage('Your password has been reset successfully.');
                setTimeout(() => navigate('/login'), 2000); // Redirect to login after 2 seconds
            } else {
                const data = await response.json();
                setError(data.detail || 'Failed to reset password. The link may be invalid or expired.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred while resetting the password.');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', textAlign: 'center' }}>
            <h2>Reset Your Password</h2>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        const errors = validatePassword(e.target.value);
                        setPasswordErrors(errors);
                    }}
                    required
                    style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}
                />
                {passwordErrors.length > 0 && (
                    <div style={{ color: 'red', textAlign: 'left', marginBottom: '10px' }}>
                        {passwordErrors.map((err, index) => (
                            <p key={index} style={{ margin: 0 }}>
                                {err}
                            </p>
                        ))}
                    </div>
                )}
                <input
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        if (e.target.value !== password) {
                            setConfirmPasswordError('Passwords do not match.');
                        } else {
                            setConfirmPasswordError('');
                        }
                    }}
                    required
                    style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}
                />
                {confirmPasswordError && (
                    <p style={{ color: 'red', textAlign: 'left', marginBottom: '10px' }}>
                        {confirmPasswordError}
                    </p>
                )}
                <button
                    type="submit"
                    disabled={isLoading}
                    style={{
                        padding: '10px',
                        borderRadius: '5px',
                        backgroundColor: '#5a4d9f',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    {isLoading ? 'Resetting Password...' : 'Reset Password'}
                </button>
            </form>
        </div>
    );
}

export default PasswordResetConfirm;