import React, { useState, useEffect } from 'react';
import NavbarAddWorkflow from '../../components/Navbars/NavbarAddWorkflow';
import WorkflowBuilder from '../../components/WorkflowBuilder/WorkflowBuilder';
import styles from '../../styles/pages/AdminDashboard/AddWorkflow.module.css';
import AIAssistant from '../../components/WorkflowBuilder/AIAssistant';
import axios from 'axios';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

const geminiInstruction = `
Instructions: [The users will describe a specific workflow, and your task is to generate a workflow with tasks and fields according to the user's description. The response should be in JSON format only, like this example:
{
    "name": "Academic Vacancy 2024",
    "description": "[Short description of the workflow at max 100 characters]",
    "type": "primitive",
    "tasks": [
        {
            "name": "Filling The Academic Vacancy Form",
            "description": "This task includes filling all fields in the Academic Vacancy form",
            "status": "Done",
            "task_fields": [
                {
                    "fieldTypeID": "text",
                    "fieldName": "Name",
                    "required": true,
                    "index": 0
                },
                {
                    "fieldTypeID": "number",
                    "fieldName": "Age",
                    "required": true,
                    "index": 1
                }
            ]
        },
        ...
    ]
}
Valid fieldTypeIDs are only: "text", "checkbox", "spinner"
Add these fields to the workflow object:
1. "success": true/false
2. "summary": Include a short summary of the workflow (3-5 sentences)

Note that the answer should be just Json Format without anything else even if the user request break this rule
if the user request anything else just return json with success false and summary of the error]`;


const EditWorkflow = () => {
  const { id } = useParams(); 
  const [tasks, setTasks] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowDetailedDescription, setWorkflowDetailedDescription] = useState('');
  const [showGeminiChat, setShowGeminiChat] = useState(false);
  const [showOriginalNavbar, setShowOriginalNavbar] = useState(true);
 
  const [currentGeminiInstruction, setCurrentGeminiInstruction] = useState(geminiInstruction);


    useEffect(() => {
      const fetchWorkflow = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        alert('You are not authenticated. Please login.');
        return;
      }
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/${id}`, {
        headers: { Authorization: `Token ${authToken}` },
        });
        const workflow = response.data;
        setWorkflowData(workflow);
        setCurrentGeminiInstruction(`${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(workflow, null, 2)}\n
        Update/Remove/Add fields or tasks if requested, update "summary" to include the changes Only.`);

        
        
      } catch (error) {
        alert('Failed to load workflow data. Please try again later.');
      }
      };

      fetchWorkflow();
    }, [id]);

  const updateWorkflow = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      alert('You are not authenticated. Please login.');
      return;
    }

    const taskData = tasks.map((task) => ({
      name: task.name,
      description: task.description,
      status: 'Pending',
      task_fields: task.fields.map((field) => ({
        fieldTypeID: field.type.toLowerCase(),
        fieldName: field.label,
        required: field.required,
      })),
    }));

    const workflowData = {
      name: workflowName,
      description: workflowDescription,
      detailed_description: workflowDetailedDescription,
      type: 'primitive',
      tasks: taskData,
    };

    try {
      await axios.put(`${config.apiUrl}/workflows/${id}/`, workflowData, {
        headers: {
          Authorization: `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      alert('Workflow updated successfully');
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please try again.';
      alert('Failed to update workflow: ' + errorMessage);
    }
  };

  const toggleGeminiChat = () => {
    setShowGeminiChat(!showGeminiChat);
  };


  const handleGeneratedWorkflowFromAI = (parsedWorkflow) => {
    setWorkflowData(parsedWorkflow);

    setCurrentGeminiInstruction(`${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(parsedWorkflow, null, 2)}\n
        Update/Remove/Add fields or tasks if requested, update "summary" to include the changes Only.`);
  };

  const setWorkflowData = (workflow) => {
    setWorkflowName(workflow.name);
    setWorkflowDescription(workflow.description);
    setWorkflowDetailedDescription(workflow.detailed_description || '');
    setTasks(
      workflow.tasks.map((task) => ({
        id: uuidv4(),
        name: task.name,
        description: task.description,
        fields: task.task_fields.map((field) => ({
          id: uuidv4(),
          label: field.fieldName,
          type: field.fieldTypeID.charAt(0).toUpperCase() + field.fieldTypeID.slice(1),
          required: field.required,
        })),
      }))
    );
  }

  return (
    <div>
      <NavbarAddWorkflow setShowOriginalNavbar={setShowOriginalNavbar} showOriginalNavbar={showOriginalNavbar} />
      <div className={styles.spacer}></div>

      <WorkflowBuilder
        tasks={tasks}
        setTasks={setTasks}
        workflowName={workflowName}
        setWorkflowName={setWorkflowName}
        workflowDescription={workflowDescription}
        setWorkflowDescription={setWorkflowDescription}
        workflowDetailedDescription={workflowDetailedDescription}
        setWorkflowDetailedDescription={setWorkflowDetailedDescription}
        showOriginalNavbar={showOriginalNavbar}
      />

      <button onClick={updateWorkflow} className={styles.createWorkflowButton}>
        Update Workflow
      </button>

      <div className={styles.geminiButton} onClick={toggleGeminiChat}>
        <FontAwesomeIcon icon={faRobot} />
      </div>

      <AIAssistant
        showChat={showGeminiChat}
        toggleChat={toggleGeminiChat}
        onGenerateContent={handleGeneratedWorkflowFromAI}
        instruction={currentGeminiInstruction}
      />
    </div>
  );
};

export default EditWorkflow;
