// AIAssistant.js

import React, { useState, useRef } from 'react';
import { Send, X } from 'lucide-react';
import styles from '../../styles/components/WorkflowBuilder/AIAssistant.module.css';
import { GoogleGenerativeAI } from "@google/generative-ai";

const AIAssistant = ({ showChat, toggleChat, onGenerateContent, instruction }) => {
  const [messages, setMessages] = useState([
    { role: 'assistant', content: 'Hello! How can I assist you with your workflow today?' },
    { role: 'assistant', content: 'Tell me about your workflow, and I\'ll turn it into a seamless process for you.'}
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false); // State to control typing indicator
  const textareaRef = useRef(null);

  const handleSend = async () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      setMessages([...messages, userMessage]);
      setInput('');
      setIsTyping(true);

      try {
        // Combine the instruction and user input to form the prompt
        const prompt = `${instruction}\n\nUser Request Start From Here:\n${input}`;
        console.log('Prompt:', prompt);

        
        const genAI = new GoogleGenerativeAI('AIzaSyAv1vNNiB-8UUxfFleo1j3v6NedSF7L_yY');
        const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' });
        const result = await model.generateContent(prompt);

        
        let workflowData = result.response.text().replace(/```json|```/g, '').trim();
        const parsedWorkflow = JSON.parse(workflowData);
        setIsTyping(false);
        console.log('Parsed workflow:', parsedWorkflow);

        if (parsedWorkflow.success === false) {
            const aiMessage = { role: 'assistant', content: 'Failed to generate workflow. Please try again.' };
            const error = { role: 'assistant', content: parsedWorkflow.summary };
            setMessages((prevMessages) => [...prevMessages, aiMessage, error]);
        } else {
            // Pass the parsed workflow data back to AddWorkflow.js
            onGenerateContent(parsedWorkflow);
            // Display the AI response
            const aiMessage = { role: 'assistant', content: 'Workflow generated successfully!' };
            const workflowSummary = { role: 'assistant', content: parsedWorkflow.summary };
            
            setMessages((prevMessages) => [...prevMessages, aiMessage, workflowSummary]);
        }

        
      } catch (error) {
        console.error('Error generating content:', error);
        const aiMessage = { role: 'assistant', content: 'Failed to generate workflow. Please try again.' };
        setMessages((prevMessages) => [...prevMessages, aiMessage]);
        setIsTyping(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    autoResizeTextarea(); // Adjust textarea height on change
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height up to max of 100px
    }
  };


  if (!showChat) return null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Workflow Assistant</h3>
        <button onClick={toggleChat} className={styles.closeButton}>
          <X size={16} />
        </button>
      </div>
      <div className={styles.messages}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.messageContainer} ${
              message.role === 'user' ? styles.userMessageContainer : styles.assistantMessageContainer
            }`}
          >
            <div className={message.role === 'user' ? styles.userMessage : styles.assistantMessage}>
              {message.content}
            </div>
          </div>
        ))}
        {/* Typing indicator */}
        {isTyping && (
          <div className={`${styles.messageContainer} ${styles.assistantMessageContainer}`}>
            <div className={styles.typingIndicator}>
              <div className={styles.dot}></div>
              <div className={styles.dot}></div>
              <div className={styles.dot}></div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.inputArea}>
        <textarea
          ref={textareaRef}
          placeholder="Describe your workflow..."
          value={input}
          onChange={handleInputChange}
          className={styles.inputField}
          rows="1" // Start with one row and expand if needed
        />
        <button onClick={handleSend} disabled={!input.trim()} className={styles.sendButton}>
          <Send size={16} />
        </button>
      </div>
    </div>
  );
};

export default AIAssistant;
