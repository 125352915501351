import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import styles from '../../styles/pages/AdminDashboard/ViewWorkflow.module.css';

const ViewWorkflow = () => {
    const { id } = useParams();
    const location = useLocation();
    const [workflowName] = useState(location.state?.workflowName || '');
    const [workflowUsers, setWorkflowUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchWorkflowUsers = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/workflows/${id}/users`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                setWorkflowUsers(response.data);
                setLoading(false);
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
                setError('Failed to fetch workflow details: ' + errorMessage);
                setLoading(false);
            }
        };

        fetchWorkflowUsers();
    }, [id, workflowName]);

    const navigate = useNavigate();

    const handleViewTasks = (workflowId, userId, userWorkflow) => {
        navigate(`/admin-dashboard/view/workflows/${workflowId}/users/${userId}`, {
            state: { userWorkflow }
        });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    const filteredWorkflows = workflowUsers.filter(workflowUser =>
        workflowUser.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        workflowUser.user_id.toString().includes(searchQuery) ||
        workflowUser.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container>
            <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className={styles.searchBar}
            />
            <h2>{workflowName} Workflow</h2>
            {filteredWorkflows.length > 0 ? (
                <Row>
                    {filteredWorkflows.map(userWorkflow => (
                        <Col sm={12} md={6} lg={4} key={userWorkflow.id} className={styles.col}>
                            <Card className="shadow-sm h-100">
                                <Card.Body className={styles.cardBody}>
                                    <Card.Title className={`text-center ${styles.cardTitle}`}>
                                        {userWorkflow.user_name}
                                    </Card.Title>
                                    <Card.Text className={`text-center ${styles.cardText}`}>
                                        <strong>Status:</strong> {userWorkflow.status}
                                        <div className={styles.workflowCon}>
                                            <div className={styles.workflowCount}>0%</div>
                                        </div>
                                    </Card.Text>
                                    <div className="text-center mt-4">
                                        <Button
                                            className={styles.buttonPrimary}
                                            onClick={() => handleViewTasks(userWorkflow.workflow.id, userWorkflow.user_id, userWorkflow)}
                                        >
                                            View Tasks
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

            ) : (
                <div>No users found.</div>
            )}

        </Container>
    );
};

export default ViewWorkflow;
