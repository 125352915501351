import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import AdminWorkflowCard from '../../components/workflow/AdminWorkflowCard';
import SearchBar from '../../components/common/SearchBar';
import Sidebar from '../../components/common/Sidebar';
import config from '../../config';
import styles from '../../styles/AdminWorkflowList.module.css'; 
import { FaBell, FaUserCircle } from 'react-icons/fa';

function AdminWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const [isProfileOpen, setIsProfileOpen] = useState(false); // State to handle profile dropdown
  
  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleDelete = (workflowId) => {
    if (window.confirm("Are you sure you want to delete this workflow?")) {
      axios.delete(`${config.apiUrl}/workflows/${workflowId}`, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('authToken')}`
        }
      })
        .then(() => {
          setWorkflows(workflows.filter(workflow => workflow.id !== workflowId));
        })
        .catch(error => {
          console.error('Failed to delete the workflow:', error);
        });
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.dashboardHeader}>
          <h1>Admin Dashboard</h1>
          <div className={styles.navActions}>
            {/* Notification Icon */}
            <div className={styles.notification}>
              <FaBell size={20} />
            </div>

            {/* Profile Dropdown */}
            <div className={styles.profileDropdown} onClick={toggleProfileDropdown}>
              <FaUserCircle size={30} />
              {isProfileOpen && (
                <div className={styles.profileMenu}>
                  <Link to="/profile">Profile</Link>
                  <Link to="/settings">Settings</Link>
                  <Link to="/logout">Logout</Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.searchContainer}>
          <SearchBar value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
          <Link to="/admin-dashboard/create-workflow" className={styles.createWorkflowButton}>Create New Workflow</Link>
        </div>

        <div className={styles.workflowList}>
          {loading ? (
            <div>Loading workflows...</div>
          ) : workflows.length > 0 ? (
            workflows.map(workflow => (
              <AdminWorkflowCard
                key={workflow.id}
                workflow={workflow}
                onDelete={handleDelete} // Delete functionality
              />
            ))
          ) : (
            <div>No workflows found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminWorkflowList;
