import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/components/WorkflowDetails/WorkflowTasks.module.css';

const WorkflowTasks = ({ tasks, workflowId }) => {
    const navigate = useNavigate();

    const getColor = (status) => {
        return status === "Done" ? styles.completed : styles.inProgress;
    };

    const handleStepClick = (index) => {
        // Display the tasks and workflowId
        console.log('Fetching tasks:', tasks);
        console.log('Fetching workflowId:', workflowId);
        console.log('Task clicked:', index);
        console.log('workflowId:', workflowId);
        console.log('taskId:', tasks[index].id);
        
        // Navigate to the specific step detail page
        navigate(`/user-dashboard/workflows/${workflowId}/step/${tasks[index].id}`);
    };

    return (
        <div>
            <table className={styles.progressBarTable}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Step</th>
                        <th style={{ width: "300px" }}>Progress</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task, index) => (
                        <tr key={task.id}>
                            <td>{index + 1}</td>
                            <td>
                                <button
                                    onClick={() => handleStepClick(index)}
                                    className={styles.taskButton}
                                    style={{ color: task.status === "Done" ? "#28a745" : "#007BFF" }}
                                >
                                    {task.name}
                                </button>
                            </td>
                            <td>
                                <div className={styles.progress}>
                                    <div
                                        className={`${styles.progressBar} ${getColor(task.status)}`}
                                        style={{ width: `${task.status === "Done" ? 100 : 10}%` }}
                                    >
                                        {task.status === "Done" ? 100 : 10}%
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default WorkflowTasks;
