import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';

function ActivateAccount() {
    const { userId, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const activateAccount = async () => {
            try {
                await axios.post(`${config.apiUrl}/activate/${userId}/${token}/`);
                toast.success("Account activated successfully! Redirecting to login...");
                setTimeout(() => {
                    navigate('/login'); // Redirect after success
                }, 2000); // Delay for user feedback
            } catch (error) {
                toast.error("Activation failed. Please try again.");
                console.error("Error activating account:", error.response?.data || error.message);
            }
        };

        activateAccount();
    }, [userId, token, navigate]);

    return <div>Activating your account...</div>;
}

export default ActivateAccount;
