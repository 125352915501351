import React from 'react';
import '../../styles/SearchBar.css';

function SearchBar({ value, onChange }) {
  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search workflows..."
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default SearchBar;
