import React, { useEffect, useState } from "react";
import { ViewTask } from "./ViewTask";

export const MultiStepTask = ({ list, step, onPageUpdate, workflowId, userTask }) => {
    const [answers, setAnswers] = useState({});

    useEffect(() => {
        setAnswers({});  // Reset answers when step changes
    }, [step]);

    const updateAnswers = (value, fieldName) => {
        const updatedAnswers = { ...answers, [fieldName]: value };
        setAnswers(updatedAnswers);
        try {
            onPageUpdate(step, updatedAnswers);
        } catch (error) {
            console.error('Error updating page:', error);
        }
    };
    return (
        <div className="text-left">
            {userTask.user_task_fields.map((item, index) => (
                <ViewTask
                    key={index}
                    item={item}
                    answer={answers[item.taskField.fieldName]}
                    onChange={updateAnswers}
                    workflowId={workflowId}
                />
            ))
            }




        </div>
    );
};
