// CheckboxField.js
import React from 'react';
import styles from './CheckboxField.module.css';

function CheckboxField({ label, showLabel = true, options= ['option 1'] }) {
  return (
    <div className={styles.checkboxField}>
      {showLabel && <label>{label}</label>}
      {options.map((option, index) => (
        <div key={index}>
          <input type="checkbox" disabled />
          <span>{option}</span>
        </div>
      ))}
    </div>
  );
}

export default CheckboxField;
