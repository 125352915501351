import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './Register.module.css';
import config from '../config';
import { UserContext } from './UserContext'; 
import { initializeGoogleSignIn, handleCredentialResponse } from '../services/googleAuthService'; // Google Auth Service for handling sign-in.
import { First } from 'react-bootstrap/esm/PageItem';

function Register() {
    const [userDetails, setUserDetails] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        DateOfBirth: ''
    });
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    // Form submit handler for registration
    const handleRegister = async (event) => {
        event.preventDefault();
        if (userDetails.password !== userDetails.confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        try {
            const response = await fetch(`${config.apiUrl}/auth/register/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "username": userDetails.username,
                    "email": userDetails.email,
                    "password1": userDetails.password,
                    "password2": userDetails.confirmPassword,
                    "first_name": userDetails.FirstName,
                    "last_name": userDetails.LastName,
                    "gender": userDetails.Gender,
                    "date_of_birth": userDetails.DateOfBirth
                })
            });
            console.log(response.body);

            if (!response.ok) {
                throw new Error('Registration failed');
            }

            alert('Registration successful');
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error);
            alert('Failed to register');
        }
    };

    // Effect for handling Google Sign-In
    useEffect(() => {
        const callback = async (response) => {
            const result = await handleCredentialResponse(response.credential, config.apiUrl);
            if (result.success) {
                localStorage.setItem('authToken', result.key);
                setUser({ username: result.username });
                if (result.created) {
                    navigate('/profile');
                } else {
                    navigate('/');
                }
            } else {
                alert('Google Sign-In failed: ' + result.message);
            }
        };

        initializeGoogleSignIn(config.googleClientId, callback);
    }, [navigate, setUser]);

    return (
        <div className={style.registerComponent}>
            <div className={style.container}>
                <div className={style.leftPanel}>
                    <h1 className={style.header}>Welcome Back!</h1>
                    <p className={style.text}>Enter your personal details to use all site features</p>
                    <button className={style.toggleButton} onClick={() => navigate("/login")}>
                        Sign In
                    </button>
                </div>
                <div className={style.rightPanel}>
                    <form onSubmit={handleRegister} className={style.formGroup}>
                        <h1 className={style.headerCreate}>Create Account</h1>

                        {/* Google Sign-In element rendered here */}
                        <div id="googleSignInButton"></div>
                        <br/>

                        <hr className={style.lineSeparator} />

                        <input
                            className={style.input}
                            type="text"
                            placeholder="Username"
                            value={userDetails.username}
                            onChange={(e) => setUserDetails({ ...userDetails, username: e.target.value })}
                        />
                        <input
                            className={style.input}
                            type="email"
                            placeholder="Email"
                            value={userDetails.email}
                            onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                        />
                        <input
                            className={style.input}
                            type="password"
                            placeholder="Password"
                            value={userDetails.password}
                            onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                        />
                        <input
                            className={style.input}
                            type="password"
                            placeholder="Confirm Password"
                            value={userDetails.confirmPassword}
                            onChange={(e) => setUserDetails({ ...userDetails, confirmPassword: e.target.value })}
                        />
                        <input
                            className={style.input}
                            type="text"
                            placeholder="First Name"
                            value={userDetails.FirstName}
                            onChange={(e) => setUserDetails({ ...userDetails, FirstName: e.target.value })} 
                        />
                        <input
                            className={style.input}
                            type="text"
                            placeholder="Last Name"
                            value={userDetails.LastName}
                            onChange={(e) => setUserDetails({ ...userDetails, LastName: e.target.value })}
                        />
                        <select
                            className={style.inputSelect}
                            value={userDetails.Gender}
                            onChange={(e) => setUserDetails({ ...userDetails, Gender: e.target.value })}
                        >
                            <option value="" disabled>
                                Select Gender
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        <input
                            className={style.input}
                            type="date"
                            placeholder="Date of Birth"
                            value={userDetails.DateOfBirth}
                            onChange={(e) => setUserDetails({ ...userDetails, DateOfBirth: e.target.value })}
                        />
                        <button className={style.signInButton} type="submit">
                            Sign Up
                        </button>
                    </form>

                    
                </div>
            </div>
        </div>
    );
}

export default Register;
