import React from 'react';
import harri from './harri.png'; // Placeholder image
import styles from '../../styles/WorkflowCard.module.css'; // CSS Module for the card
import { Link } from 'react-router-dom'; // Importing Link from react-router-dom
import { FaTrashAlt, FaUsers, FaCheck } from 'react-icons/fa'; // Icons for actions
import { MdModeEditOutline } from 'react-icons/md';

function AdminWorkflowCard({ workflow, onDelete }) {
  const workflowInfo = workflow;

  return (
    <div className={styles.workflowCard}>
      <div className={styles.workflowCardHeader}>
        <img src={harri} alt={workflowInfo.name} className={styles.workflowLogo} />
        <div className={styles.imageSeparator}></div> {/* Gray line separator */}
      </div>
      <div className={styles.workflowCardBody}>
        <h2 className={styles.workflowTitle}>
          {workflowInfo.name}
          {/* Edit icon beside workflow name */}
          <Link to={`/admin-dashboard/edit-workflow/workflows/${workflow.id}`} className={styles.editIcon}>
            <MdModeEditOutline size={20} />
          </Link>
        </h2>
        <p className={styles.workflowType}>IT</p>
        <p className={styles.workflowDescription}>
          {workflowInfo.description || 'No description available.'}
        </p>
      </div>
      <div className={styles.workflowCardFooter}>
        {/* Delete button */}
        <button className={styles.deleteWorkflowButton} onClick={() => onDelete(workflow.id)}>
          <FaTrashAlt /> Delete
        </button>
        {/* View Submissions button */}
        <Link
          to={`/admin-dashboard/view/workflows/${workflow.id}`}
          state={{ workflowName: workflowInfo.name }}
          className={styles.viewSubmissionsButton}
        >
          <FaUsers /> View Submissions
        </Link>
      </div>
      {/* User count and Done status */}
      <div className={styles.bottomStats}>
        <div className={styles.userCount}>
          <FaUsers /> {workflow.user_count}
        </div>
        <div className={styles.workflowDone}>
          <FaCheck /> {workflow.status === 'Done' ? '1,000' : '0'}
        </div>
      </div>
    </div>
  );
}

export default AdminWorkflowCard;
