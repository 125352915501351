// TextField.js
import React from 'react';
import styles from './TextField.module.css'; // Assuming you're using module CSS

function TextField({ label, showLabel = true }) {
  return (
    <div className={styles.textField}>
      {showLabel && <label>{label}</label>}
      <input className = {styles.textfieldInput} type="text" placeholder="Enter text here" disabled />
    </div>
  );
}

export default TextField;
