// components/common/DeleteConfirmationModal.js
import React from 'react';
import styles from '../../styles/DeleteConfirmationModal.module.css'; // CSS Module
import { FaExclamationTriangle } from 'react-icons/fa'; // Warning icon

function DeleteConfirmationModal({ isOpen, onClose, onConfirm, workflowName }) {
  if (!isOpen) return null; // If the modal is not open, don't render anything

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalIconContainer}>
          <div className={styles.modalIcon}>
            <FaExclamationTriangle size={30} color="#FF4C4C" />
          </div>
        </div>
        <h2>Delete Workflow</h2>
        <p>
          You're going to delete the <strong>"{workflowName}"</strong> Workflow. Are you sure?
        </p>
        <div className={styles.modalButtons}>
          <button className={styles.cancelBtn} onClick={onClose}>
            No, Keep It.
          </button>
          <button className={styles.confirmBtn} onClick={onConfirm}>
            Yes, Delete!
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
