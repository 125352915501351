import React, { useState } from 'react';
import NavbarAddWorkflow from '../../components/Navbars/NavbarAddWorkflow';
import WorkflowBuilder from '../../components/WorkflowBuilder/WorkflowBuilder';
import styles from '../../styles/pages/AdminDashboard/AddWorkflow.module.css';
import AIAssistant from '../../components/WorkflowBuilder/AIAssistant'; // Import AIAssistant component
import axios from 'axios';
import config from '../../config';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const geminiInstruction = `
Instructions: [The users will describe a specific workflow, and your task is to generate a workflow with tasks and fields according to the user's description. The response should be in JSON format only, like this example:
{
    "name": "Academic Vacancy 2024",
    "description": "[Short description of the workflow at max 100 characters]",
    "type": "primitive",
    "tasks": [
        {
            "name": "Filling The Academic Vacancy Form",
            "description": "This task includes filling all fields in the Academic Vacancy form",
            "status": "Done",
            "task_fields": [
                {
                    "fieldTypeID": "text",
                    "fieldName": "Name",
                    "required": true,
                    "index": 0
                },
                {
                    "fieldTypeID": "number",
                    "fieldName": "Age",
                    "required": true,
                    "index": 1
                }
            ]
        },
        ...
    ]
}
Valid fieldTypeIDs are only: "text", "checkbox", "spinner"
Add these fields to the workflow object:
1. "success": true/false
2. "summary": Include a short summary of the workflow (3-5 sentences)

Note that the answer should be just Json Format without anything else even if the user request break this rule
if the user request anything else just return json with success false and summary of the error]`;


const AddWorkflow = () => {
  const [tasks, setTasks] = useState([]);
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [workflowDetailedDescription, setWorkflowDetailedDescription] = useState('');
  const [showGeminiChat, setShowGeminiChat] = useState(false); // Control Gemini chat visibility
  const [showOriginalNavbar, setShowOriginalNavbar] = useState(true); 


  const [currentGeminiInstruction, setCurrentGeminiInstruction] = useState(geminiInstruction);



  // Create Workflow function
  const createWorkflow = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      alert('You are not authenticated. Please login.');
      return;
    }

    const taskData = tasks.map((task, index) => ({
      name: task.name,
      description: task.description,
      status: 'Pending',
      task_fields: task.fields.map((field, fieldIndex) => ({
        fieldTypeID: field.type.toLowerCase(),
        fieldName: field.label,
        required: true,
        index: fieldIndex,
      })),
    }));

    const workflowData = {
      name: workflowName,
      description: workflowDescription,
      detailed_description: workflowDetailedDescription,
      type: 'primitive',
      tasks: taskData,
    };

    try {
      const response = await axios.post(`${config.apiUrl}/workflows/`, workflowData, {
        headers: {
          Authorization: `Token ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      alert('Workflow added successfully');
      // Reset form data
      setWorkflowName('');
      setWorkflowDescription('');
      setWorkflowDetailedDescription('');
      setTasks([]); // Reset tasks
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please check your input and try again.';
      alert('Failed to add workflow: ' + errorMessage);
    }
  };

  // Toggle Gemini Chat Window
  const toggleGeminiChat = () => {
    setShowGeminiChat(!showGeminiChat);
  };


 // Callback to handle the AI-generated workflow data
 const handleGeneratedWorkflowFromAI = (parsedWorkflow) => {
  setWorkflowName(parsedWorkflow.name);
  setWorkflowDescription(parsedWorkflow.description);
  setWorkflowDetailedDescription('This is a detailed description of the workflow');

  const clonedTasks = cloneDeep(parsedWorkflow.tasks.map((task) => ({
    id: uuidv4(),
    name: task.name,
    description: task.description,
    fields: task.task_fields.map((field) => ({
      id: uuidv4(),
      label: field.fieldName,
      type: field.fieldTypeID.charAt(0).toUpperCase() + field.fieldTypeID.slice(1),
      required: field.required,
      config: field.fieldTypeID === 'text' ? { placeholder: `Enter ${field.fieldName}` } :
        field.fieldTypeID === 'number' ? { placeholder: `Enter ${field.fieldName}`, type: 'number' } :
        field.fieldTypeID === 'checkbox' ? { label: field.fieldName } :
        { label: field.fieldName, options: ['Option 1', 'Option 2', 'Option 3'], placeholder: `Select ${field.fieldName}` },
    })),
  })));
  setTasks(clonedTasks);

  setCurrentGeminiInstruction(`${geminiInstruction}\n\nCurrent Workflow:\n${JSON.stringify(parsedWorkflow, null, 2)}\n
  Update fields or add tasks if requested, update "summary" to include the changes Only. Create a new workflow if the request is unrelated to the current workflow.`);
  
  }


  return (
    <div>
      <NavbarAddWorkflow setShowOriginalNavbar={setShowOriginalNavbar} showOriginalNavbar={showOriginalNavbar} />
      <div className={styles.spacer}></div> {/* Spacer for layout adjustment */}

      <WorkflowBuilder
        tasks={tasks}
        setTasks={setTasks}
        workflowName={workflowName}
        setWorkflowName={setWorkflowName}
        workflowDescription={workflowDescription}
        setWorkflowDescription={setWorkflowDescription}
        workflowDetailedDescription={workflowDetailedDescription}
        setWorkflowDetailedDescription={setWorkflowDetailedDescription}
        showOriginalNavbar={showOriginalNavbar}
      />

      {/* Create Workflow Button */}
      <button onClick={createWorkflow} className={styles.createWorkflowButton}>
        Create Workflow
      </button>

      {/* Gemini Floating Button */}
      <div className={styles.geminiButton} onClick={toggleGeminiChat}>
        <FontAwesomeIcon icon={faRobot} />
      </div>

      {/* Gemini Chat Window */}
      {/* Pass geminiInstruction and handleGeneratedWorkflow to AIAssistant */}
      <AIAssistant
        showChat={showGeminiChat}
        toggleChat={toggleGeminiChat}
        onGenerateContent={handleGeneratedWorkflowFromAI}
        instruction={currentGeminiInstruction}
      />
    </div>
  );
};

export default AddWorkflow;
