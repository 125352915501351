import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import config from '../../config';
import styles from '../../styles/pages/BrowseWorkflows/PreviewWorkflowDetails.module.css';


const PreviewWorkflowDetails = () => {
    const { id } = useParams();
    const [workflow, setWorkflow] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [addingWorkflowId, setAddingWorkflowId] = useState(null);
    const [addWorkflowError, setAddWorkflowError] = useState('');

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            console.error('No auth token available');
            setError('Authentication required.');
            setLoading(false);
            return;
        }

        axios.get(`${config.apiUrl}/workflows/${id}/`, {
            headers: { 'Authorization': `Token ${authToken}` }
        })
            .then(response => {
                setWorkflow(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching workflows:', error);
                setError('Failed to fetch workflows. Please try again.');
                setLoading(false);
            });
    }, [id]);

    const handleAddWorkflow = (workflowId) => {
        console.log('Adding workflow:', workflowId);
        setAddingWorkflowId(workflowId);
        setAddWorkflowError('');

        axios.post(`${config.apiUrl}/user-workflows/1`,
            { workflow_id: workflowId, status: 'active' },  // Ensure correct field name
            { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
        )
            .then(response => {
                alert('Workflow added successfully');
                setAddingWorkflowId(null);
                // Update UI or Redirect as necessary
            })
            .catch(error => {
                console.error('Failed to add workflow:', error.response.data);
                setAddWorkflowError(`Failed to add workflow: ${error.response.data}`);
                setAddingWorkflowId(null);
            });
    };

    if (loading) return <div>Loading workflows...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            {workflow ? (
                <Container className={styles.container}>
                    <Row className={styles.row}>
                        <Col>
                            <h2>{workflow.name}</h2>
                            <p>{workflow.description}</p>
                        </Col>
                        <Card className={styles.card}>
                            <Card.Body className={styles.cardBody}>
                                {workflow.tasks.length > 0 ? workflow.tasks.map((task, index) => (
                                    <div key={index}>
                                        <h3>Task #{index + 1}: {task.name}</h3>
                                        <p>Description: {task.description}</p>
                                    </div>
                                )) : <div>No tasks found.</div>}
                            </Card.Body>
                            <Card.Footer className={styles.cardFooter}>
                                <button
                                    className={styles.addWorkflowButton}
                                    onClick={() => handleAddWorkflow(workflow.id)}
                                    disabled={addingWorkflowId === workflow.id}
                                >
                                    {addingWorkflowId === workflow.id ? 'Adding...' : 'Add Workflow'}
                                </button>
                            </Card.Footer>
                        </Card>
                    </Row>
                </Container>
            ) : (
                <h2>Loading...</h2>
            )}
        </div>
    );
};

export default PreviewWorkflowDetails;
