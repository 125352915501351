// SpinnerField.js
import React from 'react';
import styles from './SpinnerField.module.css';

function SpinnerField({ label, showLabel = true, options }) {
  return (
    <div className={styles.spinnerField}>
      {showLabel && <label>{label}</label>}
      <select disabled>
        {options.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default SpinnerField;
