import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import logo from '../../assets/images/logo.png';
import styles from '../../styles/components/Navbars/NavbarAddWorkflow.module.css';


const NavbarAddWorkflow = ({ setShowOriginalNavbar, showOriginalNavbar }) => {
  const navigate = useNavigate();
  const [lastScrollY, setLastScrollY] = useState(0);
  const [constantNavbarTop, setConstantNavbarTop] = useState(false); // Track if constant navbar should be at the top

  const navigateToDashboard = () => {
    navigate('/admin-dashboard');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setShowOriginalNavbar(true); // Show original navbar only when at the top
        setConstantNavbarTop(false); // Constant navbar stays below
      } else if (window.scrollY > lastScrollY) {
        setShowOriginalNavbar(false); // Hide original navbar on scroll down
        setConstantNavbarTop(true); // Move constant navbar to the top
      }
      setLastScrollY(window.scrollY); // Update last scroll position
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div>
      {/* Original Navbar */}
      <div className={`${styles.originalNavbar} ${showOriginalNavbar ? styles.show : styles.hide}`}>
        <div className={styles.logoSection} onClick={navigateToDashboard}>
          <img src={logo} alt="WorkflowMaster Logo" className={styles.logo} />
          <span className={styles.logoText}>WorkflowMaster</span>
          <div
            className={styles.workflowBuilderText}
            onClick={(e) => e.stopPropagation()} // Prevents navigation for Workflow Builder
          >
            Workflow Builder
          </div>
        </div>
        <div className={styles.rightSection}>
          <button className={styles.navButton}>Help</button>
          <div className={styles.profileIcon}>M</div> {/* User's profile circle */}
        </div>
      </div>

      {/* Constant Navbar for Build and Settings */}
      <div className={`${styles.fixedNavbar} ${constantNavbarTop ? styles.topPosition : ''}`}>
        <button className={styles.navTab}>Build</button>
        <button className={styles.navTab}>Settings</button>
      </div>
    </div>
  );  
};

export default NavbarAddWorkflow;
