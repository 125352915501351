import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { MultiStepTask } from '../../components/WorkflowDetailsAdmin/MultiStepTask'; 
import styles from '../../styles/pages/AdminDashboard/ViewUserTasks.module.css';

const ViewUserTasks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userWorkflow } = location.state;

    const userTasks = userWorkflow.user_tasks;
    const workflowId = userWorkflow.workflow.id;

    const [selectedTask, setSelectedTask] = useState(null);
    const [stepId, setStepId] = useState(null);

    const getColor = (status) => {
        if (status === "Done") return '#28a745'; // Green for completed tasks
        return '#007BFF'; // Blue for uncompleted tasks
    };

    const handleStepClick = (index) => {
        setStepId(userTasks[index].id);
        setSelectedTask(userTasks[index]);
    };

    const handlePageUpdate = (step, data) => {
        console.log(`Data updated at step ${step}:`, data);
    };

    return (
        <Container className={styles.container}>
            <h2 className={styles.title}>Tasks for {userWorkflow.user_name}:</h2>
            {!selectedTask && (
                <div>
                    <table className={styles.progressBarTable}>
                        <thead>
                            <tr>
                                <th className={styles.tableHeader}>#</th>
                                <th className={styles.tableHeader}>Step</th>
                                <th className={styles.tableHeader} style={{ width: "300px" }}>Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userTasks.map((task, index) => (
                                <tr 
                                    key={task.id} 
                                    className={index % 2 === 0 ? styles.tableRowEven : ''} 
                                >
                                    <td className={styles.tableData}>{index + 1}</td>
                                    <td className={styles.tableData}>
                                        <button 
                                            className={styles.tableButton} 
                                            onClick={() => handleStepClick(index)}
                                            style={{ color: getColor(task.status) }}
                                        >
                                            {task.name}
                                        </button>
                                    </td>
                                    <td className={styles.tableData}>
                                        <div className={styles.progress}>
                                            <div
                                                className={styles.progressBar}
                                                style={{
                                                    width: `${task.status === "Done" ? 100 : 10}%`,
                                                    backgroundColor: getColor(task.status)
                                                }}
                                            >
                                                {task.status === "Done" ? 100 : 10}%
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {selectedTask && (
                <Container className={styles.marginTop5}>
                    <Row className={styles.marginBottom3}>
                        <Col>
                            <h2>{selectedTask.name}</h2>
                            <p>{selectedTask.description}</p>
                        </Col>
                    </Row>
                    <Card className={styles.card}>
                        <Card.Body className={styles.cardBody}>
                            <MultiStepTask 
                                list={selectedTask} 
                                step={parseInt(stepId, 10) - 1} 
                                onPageUpdate={handlePageUpdate} 
                                workflowId={workflowId} 
                                userTask={selectedTask} 
                            />
                        </Card.Body>
                    </Card>
                    <Row className={styles.marginTop3}>
                        <Col>
                            <button 
                                className={`${styles.button} ${styles.btnPrimary}`} 
                                onClick={() => setSelectedTask(null)}
                            >
                                Back to user tasks
                            </button>
                        </Col>
                    </Row>
                </Container>
            )}
        </Container>
    );
};

export default ViewUserTasks;
