import React, { useState } from 'react';
import '../../styles/Sidebar.css';
import { NavLink } from 'react-router-dom'; // Use NavLink for active links

import logo from '../../assets/images/logo.png'; // 

function Sidebar() {
  return (
    <div className="sidebar">
      {/* Creative Site Name with Logo */}
      <div className="site-header">
        <img src={logo} alt="WorkflowMaster Logo" className="logo" />
        <h1>WorkflowMaster</h1>
      </div>
      <ul>
        <li>
          <NavLink
            to="/user-dashboard"
            className="sidebar-link"
            activeClassName="active"
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/browse-workflows"
            className="sidebar-link"
            activeClassName="active"
          >
            Browse Workflows
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin-dashboard"
            className="sidebar-link"
            activeClassName="active"
          >
            Admin Dashboard
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;

