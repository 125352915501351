import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import UserWorkflowCard from '../../components/workflow/UserWorkflowCard';
import SearchBar from '../../components/common/SearchBar';
import Sidebar from '../../components/common/Sidebar';
import config from '../../config';
import '../../styles/UserWorkflowList.css'; 
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal'; 
import { FaBell, FaUserCircle } from 'react-icons/fa';

function UserWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const [isProfileOpen, setIsProfileOpen] = useState(false); // State to handle profile dropdown
  const [isModalOpen, setIsModalOpen] = useState(false); // State for the delete confirmation modal
  const [workflowToDelete, setWorkflowToDelete] = useState(null); // Track the workflow to delete
  
  
  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  // Function to handle delete workflow
  const handleDelete = (workflow) => {
    setWorkflowToDelete(workflow); // Set the workflow to delete
    setIsModalOpen(true); // Open the modal
  };

  // Confirm deletion of workflow
  const confirmDelete = () => {
    axios.delete(`${config.apiUrl}/user-workflows/${workflowToDelete.id}`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('authToken')}`
      }
    })
      .then(() => {
        setWorkflows(workflows.filter(workflow => workflow.id !== workflowToDelete.id));
        setIsModalOpen(false); // Close the modal after deletion
      })
      .catch(error => {
        console.error('Failed to delete the workflow:', error);
      });
  };

  // Cancel deletion
  const cancelDelete = () => {
    setIsModalOpen(false); // Close the modal without deleting
  };

  

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/user-workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('authToken');
          window.location.href = '/login';
        }
        
      }
    };
    fetchWorkflows();
  }, []);

  const filteredWorkflows = workflows;

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="content">
        <div className="dashboard-header">
          <h1>Dashboard</h1>
          <div className="nav-actions">
            {/* Notification Icon */}
            <div className="notification">
              <FaBell size={20} />
            </div>

            {/* Profile Dropdown */}
            <div className="profile-dropdown" onClick={toggleProfileDropdown}>
              <FaUserCircle size={30} />
              {isProfileOpen && (
                <div className="profile-menu">
                  <Link to="/profile">Profile</Link>
                  <Link to="/settings">Settings</Link>
                  <Link to="/logout">Logout</Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="search-container">
          <SearchBar value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
        </div>

        <div className="workflow-list">
          {loading ? (
            <div>Loading workflows...</div>
          ) : filteredWorkflows.length > 0 ? (
            filteredWorkflows.map(workflow => (
              <UserWorkflowCard
                key={workflow.id}
                workflow={workflow}
                isUserDashboard={true} 
                onDelete={() => handleDelete(workflow)} 
              />
            ))
          ) : (
            <div>No workflows found.</div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {workflowToDelete && (
        <DeleteConfirmationModal 
          isOpen={isModalOpen} 
          onClose={cancelDelete} 
          onConfirm={confirmDelete} 
          workflowName={workflowToDelete.workflow.name} 
        />
      )}
    </div>
  );
}

export default UserWorkflowList;
