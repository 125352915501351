import React from 'react';
import harri from './harri.png'; 
import { Link } from 'react-router-dom';
import '../../styles/WorkflowCard.css'; 

function UserWorkflowCard({ workflow, onDelete }) {
  const workflowInfo = workflow.workflow; // Assuming this structure

  return (
    <div className="workflow-card">
      {/* Wrap the card content in a Link */}
      <Link to={`/user-dashboard/workflows/${workflow.id}`} className="workflow-link">
        <div className="workflow-card-header">
          <img src={harri} alt={workflowInfo.name} className="workflow-logo" />
          <div className="image-separator"></div> {/* Gray line separator */}
        </div>
        <div className="workflow-card-body">
          <h2>{workflowInfo.name}</h2>
          <p className="workflow-type">IT</p> {/* 'IT' as a placeholder for workflow type */}
          <p className="workflow-description">
            {workflowInfo.description || 'No description available.'}
          </p>
          <span className={`workflow-status ${workflow.status.toLowerCase()}`}>
            {workflow.status}
          </span>
        </div>
      </Link>
      {/* Footer with delete button outside the Link */}
      <div className="workflow-card-footer">
        <button
          className="delete-workflow-button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent navigation when delete is clicked
            onDelete(workflow.id);
          }}
        >
          <i className="fas fa-trash-alt"></i> Delete
        </button>
        <div className="progress-ring">
          <svg className="progress-circle" width="50" height="50">
            <circle
              className="progress-circle-bg"
              r="20"
              cx="25"
              cy="25"
            />
            <circle
              className="progress-circle-fill"
              stroke="#6a1b9a"
              strokeWidth="8"
              strokeDasharray="125.6"
              strokeDashoffset={125.6 - (125.6 * 70) / 100}
              fill="transparent"
              r="20"
              cx="25"
              cy="25"
            />
          </svg>
          <span className="progress-text">{90 || 0}%</span>
        </div>
      </div>
    </div>
  );
}

export default UserWorkflowCard;