import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BrowseWorkflowCard from '../../components/workflow/BrowseWorkflowCard'; // Component for browse-specific card
import SearchBar from '../../components/common/SearchBar';
import Sidebar from '../../components/common/Sidebar';
import config from '../../config';
import '../../styles/BrowseWorkflowList.css'; // Assuming separate CSS for this page
import { FaBell, FaUserCircle } from 'react-icons/fa';

function BrowseWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false); // State to handle profile dropdown
  
  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleAddWorkflow = (workflowId) => {
    axios.post(`${config.apiUrl}/user-workflows/`, 
      { workflow_id: workflowId, status: 'active' },  
      { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
    ).then(response => {
      alert('Workflow added successfully');
      // Optional: Refetch or update the UI if needed
    }).catch(error => {
      console.error('Failed to add workflow:', error.response.data);
      alert('Failed to add workflow');
    });
  };

  const filteredWorkflows = workflows.filter(workflow => 
    workflow.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    workflow.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="content">
        <div className="dashboard-header">
          <h1>Browse Workflows</h1>
          <div className="nav-actions">
            <div className="notification">
              <FaBell size={20} />
            </div>
            <div className="profile-dropdown" onClick={toggleProfileDropdown}>
              <FaUserCircle size={30} />
              {isProfileOpen && (
                <div className="profile-menu">
                  <Link to="/profile">Profile</Link>
                  <Link to="/settings">Settings</Link>
                  <Link to="/logout">Logout</Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="search-container">
          <SearchBar value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
        </div>

        <div className="workflow-list">
          {loading ? (
            <div>Loading workflows...</div>
          ) : filteredWorkflows.length > 0 ? (
            filteredWorkflows.map(workflow => (
              <BrowseWorkflowCard
                key={workflow.id}
                workflow={workflow}
                onAdd={() => handleAddWorkflow(workflow.id)}
              />
            ))
          ) : (
            <div>No workflows found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrowseWorkflowList;
