// AuthPage.js

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './AuthPage.module.css';
import { initializeGoogleSignIn, handleCredentialResponse } from '../services/googleAuthService';
import config from '../config';
import { UserContext } from './UserContext';
import { last } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Password } from 'primereact/password';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';

const AuthPage = () => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false); // New state for reset password
    const [isResendActivation, setIsResendActivation] = useState(false); // State for resend activation

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const validatePassword = (password) => {
        const validationErrors = [];
        if (password.length < 9 ) validationErrors.push('*Your password must contain at least 9 characters.');
        if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) validationErrors.push('*Your password must contain both upper-case and lower-case letters.');
        if (!/\d/.test(password)) validationErrors.push('*Your password must contain at least one numeric digit.');
        if (!/[!@#$%^&*]/.test(password)) validationErrors.push('*Your password must contain at least one special character.');
        return validationErrors;
    };


    const handleLoginErrors = (errorData) => {
        const newErrors = {};
    
        if (errorData.email_or_username) {
            newErrors.email = errorData.email_or_username;
        }
        if (errorData.password) {
            newErrors.password = errorData.password;
        }
        if (errorData.non_field_errors) {
            newErrors.email = errorData.non_field_errors;
        }
    
        setErrors(newErrors);
    };


    const handleLogin = async (event) => {
        event.preventDefault();
        const newErrors = {};
    
        if (!formData.email) {
            newErrors.email = '*Email or username is required';
        }
    
        if (!formData.password) {
            newErrors.password = ['*Password is required'];
        }
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        setIsLoading(true); // Start loading
    
        try {
            const response = await fetch(`${config.apiUrl}/auth/login/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email_or_username: formData.email, password: formData.password }),
            });
    
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.key);
                setUser({ username: data.username });
                setIsLoading(false); // Stop loading
                navigate('/');
            } else {
                const errorData = await response.json();
                handleLoginErrors(errorData);
                setIsLoading(false); // Stop loading
            }
        } catch (error) {
            console.error('Login error:', error);
            toast.error('An error occurred. Please try again.');
            setIsLoading(false); // Stop loading
        }
    };

    // Toggle between the sign-in and sign-up forms
    const handleToggle = () => {
        if (isSignUp) {
            navigate('/login');
        } else {
            navigate('/register');
        }
        setErrors({});
        setFormData({ firstName: '', lastName: '', email: '', password: '' });
        setIsResetPassword(false);
        setIsResendActivation(false);
    };

    useEffect(() => {
        if (location.pathname === '/login') {
            setIsSignUp(false);
        } else if (location.pathname === '/register') {
            setIsSignUp(true);
        }
    }, [location]);


    // Initialize Google Sign-In
    useEffect(() => {
        const callback = async (response) => {
            const result = await handleCredentialResponse(response.credential, config.apiUrl);
            if (result.success) {
                localStorage.setItem('authToken', result.key);
                setUser({ username: result.username });
                navigate(result.created ? '/profile' : '/');
            } else {
                toast.error('Google Sign-In failed: ' + result.message);
            }
        };

        initializeGoogleSignIn(config.googleClientId, callback);
    }, [navigate, setUser]);

    // Validate the email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        const newFormData = {
            ...formData,
            [name]: value,
        };
        setFormData(newFormData);

        const newErrors = { ...errors };

        // Validate First Name
        if (name === 'firstName') {
            if (!value.trim()) {
                newErrors.firstName = '*First Name is required';
            } else {
                delete newErrors.firstName;
            }
        }

        // Validate Last Name
        if (name === 'lastName') {
            if (!value.trim()) {
                newErrors.lastName = '*Last Name is required';
            } else {
                delete newErrors.lastName;
            }
        }

        // Validate Email
        if (name === 'email') {
            if (!value.trim()) {
                newErrors.email = '*Email is required';
            } else if (!validateEmail(value)) {
                newErrors.email = '*Email is invalid';
            } else {
                delete newErrors.email;
            }
        }

        // Validate Password
        if (name === 'password' && isSignUp) {
            const passwordErrors = validatePassword(value);
            if (passwordErrors.length > 0) {
                newErrors.password = passwordErrors;
            } else {
                delete newErrors.password;
            }
        }

        setErrors(newErrors);
    };

    // Handle form submission for registration
    const handleRegister = async (e) => {
        e.preventDefault();
        const newErrors = {};
    
        // Re-validate First Name
        if (!formData.firstName.trim()) {
            newErrors.firstName = '*First Name is required';
        }
    
        // Re-validate Last Name
        if (!formData.lastName.trim()) {
            newErrors.lastName = '*Last Name is required';
        }
    
        // Re-validate Email
        if (!formData.email.trim()) {
            newErrors.email = '*Email is required';
        } else if (!validateEmail(formData.email)) {
            newErrors.email = '*Email is invalid';
        }
    
        // Re-validate Password
        const passwordErrors = validatePassword(formData.password);
        if (passwordErrors.length > 0) {
            newErrors.password = passwordErrors;
        }
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        setIsLoading(true); // Start loading
    
        try {
            const response = await fetch(`${config.apiUrl}/auth/register/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "username": formData.firstName + formData.lastName,
                    "email": formData.email,
                    "password1": formData.password,
                    "password2": formData.password,
                    "first_name": formData.firstName,
                    "last_name": formData.lastName
                })
            });
    
            if (!response.ok) throw new Error('Registration failed');
    
            toast.success("Registration successful! Please check your email to activate your account.");
            setIsLoading(false); // Stop loading
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error);
            toast.error('Failed to register');
            setIsLoading(false); // Stop loading
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = '*Email is required';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch(`${config.apiUrl}/auth/password-reset/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: formData.email }),
            });

            if (response.ok) {
                toast.success('If an account with that email exists, we have sent a password reset link.');
                // Optionally, navigate back to sign-in form
                setIsResetPassword(false);
                setFormData({ ...formData, email: '' });
                newErrors.email = '';
                setErrors(newErrors);
            } 
            else if (response.status===404) {
                newErrors.email = '*No account with that email exists.';
                setErrors(newErrors);
            }
            else {
                toast.error('An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleResendActivationEmail = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = '*Email is required';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch(`${config.apiUrl}/auth/resend-activation/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: formData.email }),
            });

            if (response.OK ) {
                toast.success('If an account with that email exists and is not activated, we have resent the activation link.');
                setIsResendActivation(false);
                setFormData({ ...formData, email: '' });
                newErrors.email = '';
                setErrors(newErrors);
            }
            if (response.ok) {
                toast.success('If an account with that email exists and is not activated, we have resent the activation link.');
                setIsResendActivation(false);
                setFormData({ ...formData, email: '' });
                newErrors.email = '';
                setErrors(newErrors);
            } 
            else if (response.status===404 ) {
                newErrors.email = '*No account with that email exists.';
                setErrors(newErrors);
            }
            else {
                toast.error('An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Resend activation email error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const showResetPasswordForm = (e) => {
        e.preventDefault();
        setIsResetPassword(true);
        setErrors({});
        setFormData({ ...formData, password: '' });
        setFormData({ ...formData, email: '' });
    };

    const showResendActivationForm = (e) => {
        e.preventDefault();
        setIsResendActivation(true);
        setIsResetPassword(false);
        setErrors({});
        setFormData({ ...formData, password: '' });
        setFormData({ ...formData, email: '' });
    };

    // Function to go back to sign-in form from reset password form
    const backToSignIn = (e) => {
        e.preventDefault();
        setIsResetPassword(false);
        setIsResendActivation(false);
        setErrors({});
    };

    useEffect(() => {
        document.body.classList.add(styles.authPageBody);

        // Remove body class when the component is unmounted
        return () => {
            document.body.classList.remove(styles.authPageBody);
        };
    }, []);

    return (
        <div className={`${styles.container} ${isSignUp ? styles.active : ''}`}>
            <div className={`${styles.formContainer} ${styles.signUp}`}>
                <form onSubmit={handleRegister}>
                    <h1>Create Account</h1>
                    <div id="googleSignInButton" className={styles.googleSignIn}></div>
                    <div className={styles.nameInputs}>
                        <div className={styles.inputContainer}>
                            {errors.firstName ? <p className={styles.error}>{errors.firstName}</p> : errors.lastName ? <p className={styles.signleNameError}></p> : ''}
                      
                            

                            <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={errors.firstName ? styles.errorBorder : ''}
                            />
                        </div>
    
                        <div className={styles.inputContainer}>
                            {errors.lastName ? <p className={styles.error}>{errors.lastName}</p> : errors.firstName ? <p className={styles.signleNameError}></p> : ''}
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={errors.lastName ? styles.errorBorder : ''}
                            />
                        </div>
                    </div>
                    {errors.email && <p className={styles.error}>{errors.email}</p>}
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className={errors.email ? styles.errorBorder : ''}
                    />
    
                    <div className={styles.passwordContainer}>
                        <div className={styles.passwordField}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                className={errors.password ? styles.errorBorder : ''}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePasswordVisibility}
                                className={styles.passwordToggleIcon}
                            />
                        </div>
                        {errors.password && (
                            <div className={styles.errorList}>
                                {errors.password.map((err, index) => (
                                    <span key={index} className={styles.error}>{err}</span>
                                ))}
                            </div>
                        )}
                    </div>
    
                    <button type="submit" disabled={isLoading} className={styles.button}>
                        {isLoading ? (
                            <>
                                <ClipLoader size={15} color="#ffffff" />
                                {' Signing Up...'}
                            </>
                        ) : 'Sign Up'}
                    </button>
                    <p className={styles.terms}>
                        By signing up to create an account I accept
                        <br />
                        Company's <a href="#">Terms of Use and Privacy Policy</a>.
                    </p>
                </form>
            </div>

            <div className={`${styles.formContainer} ${styles.signIn}`}>
                {!isResetPassword && !isResendActivation ? (
                    <form onSubmit={handleLogin}>
                        <h1>Sign In</h1>
                        <div id="googleSignInButton2" className={styles.googleSignIn}></div>
                        {errors.email && <p className={styles.error}>{errors.email}</p>}
                        <input
                            type="text"
                            name="email"
                            placeholder="Email or Username"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? styles.errorBorder : ''}
                        />
    
                        {errors.password && <p className={styles.error}>{errors.password}</p>}
                        <div className={styles.passwordField}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                className={errors.password ? styles.errorBorder : ''}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePasswordVisibility}
                                className={styles.passwordToggleIcon}
                            />
                        </div>
                        <br />
                        <a href="#" className={styles.link}
                         onClick={showResetPasswordForm}>Forget Your Password?</a>
                        <br />
                        <button type="submit" disabled={isLoading} className={styles.button}>
                            {isLoading ? (
                                <>
                                    <ClipLoader size={15} color="#ffffff" />
                                    {' Signing In...'}
                                </>
                            ) : 'Sign In'}
                        </button>
                        <p>Don't have an account? <a href="#" className={styles.link} onClick={handleToggle}>Sign Up</a></p>
                        <a href="#" className={styles.link} onClick={showResendActivationForm}>Resend Activation Email</a>
                    </form>
                ) : isResetPassword ? (
                    <form onSubmit={handleResetPassword}>
                        <h1>Reset Password</h1>
                        <p className={styles.resetText}>
                            You can change your password using the form below. We will then send you a link where to set a new password.
                        </p>
                        {errors.email && <p className={styles.error}>{errors.email}</p>}
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? styles.errorBorder : ''}
                        />
                        <button type="submit" className={styles.button}>Reset Password</button>
                        <p><a href="#" className={styles.link} onClick={backToSignIn}>Back to Sign In</a></p>
                    </form>
                ) : (
                    <form onSubmit={handleResendActivationEmail}>
                        <h1>Verify your Account</h1>
                        <p className={styles.resendText}>
                            If you didn’t receive the verification link, you can resend it with the form below.
                        </p>
                        {errors.email && <p className={styles.error}>{errors.email}</p>}
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? styles.errorBorder : ''}
                        />
                        <button type="submit" className={styles.button}>Resend Activation Email</button>
                        <p><a href="#" className={styles.link} onClick={backToSignIn}>Back to Sign In</a></p>
                    </form>
                )}
            </div>
    
            <div className={styles.toggleContainer}>
                <div className={styles.toggle}>
                    <div className={styles.togglePanel + ' ' + styles.toggleLeft}>
                        <h1>Welcome Back!</h1>
                        <p>Enter your personal details to use all site features</p>
                        <button onClick={handleToggle} className={styles.button}>Sign In</button>
                    </div>
                    <div className={styles.togglePanel + ' ' + styles.toggleRight}>
                        <h1>Hello, Friend!</h1>
                        <p>Register with your personal details to use all site features</p>
                        <button onClick={handleToggle} className={styles.button}>Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    );
    
};

export default AuthPage;