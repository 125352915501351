import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { MultiStepForm } from '../../components/WorkflowDetails/MultiStepForm'; // Adjust the import path as necessary
import config from '../../config';
import styles from '../../styles/pages/UserDashboard/WorkflowStepDetail.module.css';

function WorkflowStepDetail() {
  const [task, setTask] = useState(null);
  const { id, stepId } = useParams(); // id is the workflow ID, stepId is the step/task ID
  const [userTask, setUserTask] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
          headers: {
            'Authorization': `Token ${authToken}`
          }
        });

        const taskDetail = response.data.workflow.tasks.find(t => `${t.id}` === stepId);
        if (!taskDetail) {
          console.error('Task not found');
          navigate(`/user-dashboard/workflows/${id}`);
        } else {
          console.log('Task details:', taskDetail);
          setTask(taskDetail);
        }
        const userTask = response.data.user_tasks.find(t => `${t.task}` === stepId);
        if (!userTask) {
          console.error('User task not found');
          navigate(`/user-dashboard/workflows/${id}`);
        } else {
          setUserTask(userTask);
        }

      } catch (error) {
        console.error('Failed to fetch task details:', error);
        navigate(`/user-dashboard/workflows/${id}`);
      }
    };

    fetchTask();
  }, [id, stepId, navigate]);

  const handlePageUpdate = (step, data) => {
    console.log(`Data updated at step ${step}:`, data);
    // You can add more functionality here, such as making an API call to update the server
  };

  if (!task) {
    return <p>Loading task details...</p>;
  }

  return (
    <Container className={styles.container}>
      <Row className={styles.header}>
        <Col>
          <h2 className={styles.title}>{task.name}</h2>
          <p className={styles.description}>{task.description}</p>
        </Col>
      </Row>
      <Card>
        <Card.Body className={styles.cardBody}>
          <MultiStepForm list={task} step={parseInt(stepId, 10) - 1} onPageUpdate={handlePageUpdate} workflowId={id} userTask={userTask} />
        </Card.Body>
      </Card>
      <Row className={styles.row}>
        <Col>
          <button className={styles.buttonPrimary} onClick={() => navigate(`/user-dashboard/workflows/${id}`)}>Back to Workflow</button>
        </Col>
      </Row>
    </Container>
  );
}

export default WorkflowStepDetail;
