import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditProfile.css';
import config from '../config';

function EditProfile() {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    dateOfBirth: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.get(`${config.apiUrl}/user-profile/`, {
          headers: {
            'Authorization': `Token ${authToken}`
          }
        });
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
          gender: response.data.gender,
          dateOfBirth: response.data.date_of_birth,
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        setError('Failed to fetch user profile. Please try again.');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem('authToken');
      await axios.put('${config.apiUrl}/user-profile/', {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        gender: user.gender,
        date_of_birth: user.dateOfBirth,
      }, {
        headers: {
          'Authorization': `Token ${authToken}`
        }
      });
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Failed to update profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="edit-profile">
      <h2>Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>First Name:</label>
          <input type="text" name="firstName" value={user.firstName} onChange={handleChange} />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" name="lastName" value={user.lastName} onChange={handleChange} />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={user.email} onChange={handleChange} />
        </div>
        <div>
          <label>Gender:</label>
          <select name="gender" value={user.gender} onChange={handleChange}>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div>
          <label>Date of Birth:</label>
          <input type="date" name="dateOfBirth" value={user.dateOfBirth} onChange={handleChange} />
        </div>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
}

export default EditProfile;
